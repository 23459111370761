<template>
    <div style="background: rgb(246, 245, 250);height: 100vh;">
        <div class="success" v-if="success">
            <img src="../../assets/image/success.png" alt="">
            <div>核验成功</div>
        </div>

        <div  v-if="success && h5" class="title">
            <p>如果您是网站访客，可点击“返回”按钮或直接浏览网站获取所需信息。</p>
            <p>如果您是裁判员或运动员，请点击“照片授权”，按步骤进行相关操作（如无特殊需要，请勿重复操作）。</p>
        </div>

        <div class="button" v-if="success && h5">
            <el-button @click="getrettrn">返回</el-button>
            <el-button @click="toauthorization">照片授权</el-button>
        </div>

        <div class="success" v-if="error">
            <img src="../../assets/image/error.png" alt="">
            <div>核验失败</div>
            <div style="font-size: 12PX;">{{ resultMsg }}</div>
        </div>

        <div class="button" v-if="error && h5">
            <el-button @click="getrettrn">返回</el-button>
            <el-button @click="distinguish">重新识别</el-button>
        </div>

        <div class="button pc" v-if="!h5">
            <el-button @click="getrettrn1">返回</el-button>
        </div>

        <div class="basebox">
            <img src="../../assets/image/base.png" alt="" class="base">
        </div>
    </div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {
            success: false,
            error: false,
            h5: false,
            urlParams: {},
            data: {},
            resultMsg:''
        }
    },
    created() {
        this.urlParams = new URLSearchParams(window.location.search);
        this.h5 = this.urlParams.get('isH5') == 'true'
        if(this.h5){
            this.$store.commit("EXIT_REALN")
        }
        this.resultMsg = this.urlParams.get('resultMsg')
        this.getCaStatus()
    },
    methods: {
        getCaStatus() {
            this.$api.getCaStatus({ userId: this.urlParams.get('userId') }).then(res => {
                this.data = res.data.data.result
                this.success = this.data.caStatus == 1 ? true : false
                this.error = this.data.caStatus == 2 ? true : false
            })
        },
        getrettrn() {
            this.$router.push({
                path: '/h5/registerIndex',
                query: {
                    refresh: new Date().getTime(),
                    menuIndex: 4,
                    nameornot: true,
                },
            })
        },
        getrettrn1() {
            this.$router.push({
                path: '/h5',
                query: {
                    refresh: new Date().getTime(),
                },
            })
        },
        toauthorization() {
            console.log(this.$verification(this.data.photograph))
            if (this.$verification(this.data.photograph)) {
                this.$router.push({
                    path: '/h5/registerIndex',
                    query: {
                        refresh: new Date().getTime(),
                        menuIndex: 7,
                        photoau: false,
                    },
                })
            }else if (!this.$verification(this.data.photograph)) {
                this.$router.push({
                    path: '/h5/registerIndex',
                    query: {
                        refresh: new Date().getTime(),
                        menuIndex: 7,
                        photoau: true,
                    },
                })
            }
        },
        distinguish() {
            this.$router.push({
                path: '/h5/registerIndex',
                query: {
                    refresh: new Date().getTime(),
                    menuIndex: 4,
                    nameornot: true,
                },
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.success {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20vh;

    img {
        width: 70PX;
        height: 70PX;
    }

    div {
        font-size: 20PX;
        margin-top: 20PX;
    }
}

.button {
    display: flex;
    justify-content: center;
    margin-top: 40PX;
	z-index: 999;
	position: relative;

    ::v-deep .el-button {
        width: 120PX;
    }
}

.pc {
    ::v-deep .el-button {
        width: 220PX;
    }
}

.basebox {
    position: absolute;
    bottom: 20PX;
    display: flex;
    justify-content: center;
    width: 100%;

    .base {
        width: 70vw;
    }
}

.title{
    margin: 20PX 40PX 0;
    font-size: 12PX;
    P{
        margin-bottom: 5PX;
        letter-spacing: 2PX;
    }
}
</style>